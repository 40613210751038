/** --------------------------------------------- **/
/**                   GLOBAL STYLES               **/
/** --------------------------------------------- **/

:root {
  --default-font-color: #414141;
  --default-blue-font-color: #28436a;
  --default-font-size: 14px;
  --default-red: #bd0000;
  --default-green: #1c8644;
  --default-blue: #2694d3;
  --default-orange: #af5b1b;
}

html {
  height: 100%;
}

body {
  color: var(--default-font-color);
  margin: 0;
  padding: 0;
  line-height: 1.5em;
  min-height: 100vh;
  width: 100%;
}

h4,
h4.MuiTypography-h4 {
  font-size: 25px;
}

#root {
  height: 100vh;
}

p {
  font-weight: 600;
  line-height: 30px;
  font-size: var(--default-font-size);
  font-family: sans-serif;
}

.main-container {
  margin: 2%;
  overflow: auto;
  min-height: calc(100vh - 40px);
  padding-bottom: 6rem;
}

.main-container button,
.login button {
  outline: 0;
  border: 0;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  padding: 10px 14px;
  width: 300px;
  background: #28436a;
  height: 100%;
}

.main-container .input-container button,
.login .input-container button {
  width: 200px;
}

.main-container button:hover,
.login button:hover {
  background: #0062b7;
}

.main-container button:disabled,
.login button:disabled {
  color: #e7e7e7;
  background: #8c8c8c;
}

h4.section-header {
  font-size: 30px;
  font-weight: 600;
  color: var(--default-font-color);
  padding: 30px 0;
}


/** -------------------------------------------- **/
/**                FILE UPLOAD STYLING           **/
/** -------------------------------------------- **/

.file-upload-card {
  border-radius: 5px;
  overflow: hidden;
  padding: 10x;
  margin-top: 15px;
}

.file-upload-card .drag-area {
  height: 250px;
  border-radius: 5px;
  border: 2px dashed #888888;
  color: var(--default-font-color);
  background: #eeeef1;
  /* display: flex; */
  display: grid;
  justify-content: center;
  align-items: center;
  /* user-select: none; */
  /* -webkit-user-select: none; */
  /* margin-top: 10px; */
  text-align: center;
}

.file-upload-card .drag-area svg {
  font-size: 50px;
}

.file-upload-card .drag-area .visible {
  font-size: 18px;
}

.file-upload-card .select {
  color: #252d41;
  background: #c2ccd5;
  margin-left: 5px;
  cursor: pointer;
  transition: .4s;
}

.file-upload-card .select:hover {
  opacity: 0.6;
  background: #b8c9d8;
}

.file-upload-card .files-viewer-container {
  width: 100%;
  height: auto;
  /* display: flex; */
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 20px;
}

.file-upload-card .files-viewer-container .image {
  width: 35px;
  height: 35px;
  position: relative;
  /* margin-bottom: 8px; */
}

.file-upload-card .files-viewer-container .image span {
  position: absolute;
  top: -2px;
  right: 9px;
  font-size: 20px;
  cursor: pointer;
}

.file-upload-card .files-viewer-container .image svg {
  width: 100%;
  height: 100%;
}

.file-upload-card input,
.file-upload-card .drag-area .on-drop,
.file-upload-card .drag-area.dragover .visible {
  display: none;
}

.file-upload-card .files-viewer-container .delete-image-icon {
  z-index: 999;
  color: #4f4f4f;
  margin-top: 15px;
  cursor: pointer
}

progress.file-upload-progress-bar {
  width: 100%;
  height: 15px;
}

progress[value] {
  -webkit-appearance: none;
  appearance: none;
  /* width: 100%; */
}

progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(209, 209, 209, 0.25) inset;
}

progress[value]::-webkit-progress-value {
  background-image:
    -webkit-linear-gradient(-45deg,
      transparent 33%, rgba(77, 190, 103, 0.153) 33%,
      rgba(190, 232, 209, 0.082) 66%, transparent 66%),
    -webkit-linear-gradient(top, rgb(110, 149, 129), rgb(46, 147, 43)),
    -webkit-linear-gradient(left, rgb(115, 144, 129), rgb(46, 147, 43));

  border-radius: 5px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

/* Upload Message component */
.upload-message-box {
  padding: 10px;
}

.upload-message-box p.success-message,
.upload-message-box svg.success-icon path,
.mfa-setup svg.success-icon path {
  color: var(--default-green);
  font-size: 16px;
}

.upload-message-box p.error-message,
.upload-message-box svg.error-icon path {
  color: var(--default-red);
}

.mfa-setup svg.success-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}


/** -------------------------------------------- **/
/**                  SUBMISSIONS                 **/
/** -------------------------------------------- **/

.submissions {
  border-radius: 5px;
}

.submissions table th {
  background: var(--default-font-color);
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.submissions table tbody tr:nth-of-type(even) {
  background: rgb(241, 241, 241);
}

.submissions table tbody tr,
.submissions table tbody td {
  border: 1px solid #ddd;
  font-size: var(--default-font-size);
}

.submissions table tbody tr td.state {
  font-weight: 600;
  text-transform: uppercase;
}

.submissions table tbody tr td.failed {
  color: var(--default-red);
}

.submissions table tbody tr td.succeeded {
  color: var(--default-green);
}

.submissions table tbody tr td.in-progress {
  color: var(--default-blue);
}

.submissions table tbody tr td svg {
  vertical-align: middle;
  margin-right: 5px;
}

.hideme,
svg.hideme {
  display: none;
}

svg.in-progress-icon {
  color: var(--default-blue);
}

svg.submissions-tbl-icon {
  margin-left: 10px;
  font-size: 30px;
  color: var(--default-blue);
  cursor: pointer;
}

/** -------------------------------------------- **/
/**                AUTH COMPONENTS               **/
/** -------------------------------------------- **/

.dark-page {
  background-image: linear-gradient(to right bottom, #092959, #142a53, #1b2b4d, #202c47, #252d41);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  min-height: 100%;
}

.login {
  margin: 0 auto;
  padding: 50px;
  border-radius: 8px;
  border: 3px solid var(--default-orange);
  background: #e4e4e4;
  display: block;
  width: 700px;
}

.login .input-container {
  margin: 5px;
}

.login .input-container input,
.login .input-container div.MuiSelect-select {
  width: 250px;
  padding: 10px;
  border-radius: 5px;
}

.login .input-container input#phone_number {
  width: 230px;
}

.auth-tabs button.MuiTab-root {
  background: none;
  color: var(--default-font-color);
}

.auth-tabs .MuiTabs-indicator {
  border-bottom: 3px solid var(--default-blue);
}

p.forgot-password-text {
  font-weight: 600;
  color: var(--default-orange);
  cursor: pointer;
}

.qrcode-container {
  width: 50%;
  margin-top: 15px;
  border-radius: 5px;
  border: 3px solid #ef7c43;
}

header.appbar {
  background: hsl(240, 10%, 94%);
  border-bottom: 5px solid var(--default-blue);
  color: var(--default-font-color);
}

header.appbar button {
  font-weight: 600;
  color: var(--default-font-color);
  border: 1px solid #afafaf;
  padding: 10px;
  background: #c2ccd5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 13px;
}

.app-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: var(--default-font-color);
  color: #FFF;
  padding: 15px 0;
  height: 40px;
}

.app-footer h6 {
  line-height: 35px;
  margin: inherit;
}

ol.mfa_steps li {
  line-height: 2em;
}

.login img.logo {
  display: block;
  margin: auto;
  width: 250px;
}

.appbar img.logo {
  width: 150px;
  padding: 10px;
}

.tabs {
  margin-top: 2em;
}

.tabs .MuiTabs-indicator {
  height: 5px;
  background-color: #c2ccd5;
}

button.tab-item {
  background: #eeeef1;
  text-transform: none;
  padding: 15px;
  font-weight: 600;
  font-size: 16px;
  color: #414141;
  margin-right: 5px;
}

button.tab-item:hover {
  background: #c2ccd5;
}

.submission-log-grid h6 {
  font-size: 20px;
  font-weight: 600;
  color: var(--default-font-color);
}

/* Table pagination buttons customization */
.MuiTablePagination-actions .MuiButtonBase-root {
  width: 65px;
  margin: 5px;
}

button.download {
  background: none;
  color: #28436a;
  width: auto;
  border-bottom: 3px solid #c2ccd5;
  border-radius: 0px;
  padding: 5px 10px;
}

button.download:hover {
  background: none;
}